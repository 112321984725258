import { Component, OnInit, AfterViewInit, Inject, HostListener, Input } from "@angular/core";
import { NavBarService } from "./nav-bar-mensajes.service";
import { MensajeNavBar } from "./nav-bar-mensajes.object";
//import { USER_AUTH, IUserAuth } from 'vesta-authentication';

@Component({
  selector: "nav-bar",
  templateUrl: './nav-bar-mensajes.component.html',
  styleUrls: ['./nav-bar-mensajes.component.css']
})

export class NavbarMensajesComponent implements AfterViewInit, OnInit {
  //Mensajes: Array<string>;
  constructor(private navBarService: NavBarService) { }//, @Inject(USER_AUTH) private user: IUserAuth


  @HostListener('window:resize', ['$event']) onResize(event) {
    event.target.innerWidth;
  }

  Mensajes: Array<MensajeNavBar> = this.navBarService.GetMensajesArray();
  //@Input("mensajes") Mensajes: Array<MensajeNavBar>;
  Search: string;
  @Input("key") key: string;

  ngOnInit() {
    this.Search = "";
    //if (this.key == null) throw 'Defina un valor para Input key, <nav-bar [key]="value"></nav-bar> '
    //this.Mensajes = [];
    this.navBarService.GetMensaje().subscribe(response => {
      this.Mensajes.push(response);
      this.navBarService.SrcNewMessage();
      this.Search = "";      
      localStorage.setItem(`mensajes.${this.key}`, JSON.stringify(this.Mensajes));//this.user.Id
    })

    this.navBarService.ClearMensajes().subscribe(response => {
      this.Mensajes = [];
      this.navBarService.SrcDefault();
    })

    //this.user.User.then(async user => {
    //  if (user.Id == null) return void 0;
    //  let arrayMensajes = await this.GetDataMensajes();

    //  if (Array.isArray(arrayMensajes)) {
    //    this.Mensajes = this.GetMensajesLocalStorage().concat(arrayMensajes);
    //    if (this.Mensajes.length > 0) this.navBarService.SrcNewMessage();
    //    localStorage.setItem(`mensajes.${this.user.Id}`, JSON.stringify(this.Mensajes));
    //  };
    //})   
  } 

 

  ngAfterViewInit() {
    $('.sidebar').hover(function () {
      $(".sidebar").removeClass("compact");
    });
    $('.sidebar').mouseleave(function () {
      $(".sidebar").addClass("compact");
    });

  }

  CloseNav = () => {
    this.navBarService.OpenCloseNav();
  }

  DeleteMensaje(index: number) {
    let mensaje = this.Mensajes[index];
    this.Mensajes.splice(index, 1);

    localStorage.setItem(`mensajes.${this.key}`, JSON.stringify(this.Mensajes));
    this.navBarService.OnDeleted(mensaje.Id);

    if (this.Mensajes.length == 0) {
      this.navBarService.SrcDefault();
    }
  }

  EventClick(evento: string) {
    console.log(evento);
  }


  //ngOnInit() {
  //    //this.Mensajes = [];
  //}

  //UpdateAforador = (mensaje: any) => {
  //    this.Mensajes.push(mensaje);
  //}    
}
