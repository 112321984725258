export class MensajeNavBar {
  Mensaje: string;
  Success: boolean;
  Evento: string;
  Id: string;

  constructor() {
    this.Mensaje = "";
    this.Success = false;
    this.Evento = null;
    this.Id = '00000000-0000-0000-0000-000000000000';
  }

  public static GetMensajeNavBar(id: string, mensaje: string, success: boolean = false, evento: string = null): MensajeNavBar {
    var obj = new MensajeNavBar();
    obj.Mensaje = mensaje;
    obj.Success = success;
    obj.Evento = evento;
    obj.Id = id;

    return obj;
  }
}
