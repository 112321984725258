<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


  <!--<header class="app-bar fixed-top" data-role="appbar">

    <a class="app-bar-element" routerLink="/menu">
      <span id="toggle-tiles-dropdown" class="mif-apps mif-2x"></span>
    </a>
    <div class="app-bar-divider"></div>





    <ul class="app-bar-menu small-dropdown place-right">
      <li>
        <a style="width: 60px;" (click)="NavOpen()"><div class="img" [ngClass]="_navBarService.Src"></div></a>
      </li>
    </ul>
    <div class="app-bar-divider place-right"></div>

    <ul class="app-bar-menu small-dropdown place-right">
      <li>
        <a class="dropdown-toggle place-right right app-bar-element">test</a>
        <ul class="d-menu" data-role="dropdown">
          <li><a (click)="Logout()">Logout</a></li>
          <li><a href="https://vestaidentityserver.azurewebsites.net/Identity/Account/Manage" target="_blank">Configuracion</a></li>
        </ul>
      </li>
    </ul>
    <div class="app-bar-divider place-right"></div>
  </header>-->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->



<nav-bar [key]="'test'"></nav-bar>
<div id="main" class="c-offcanvas-content-wrap" style="min-height: 82vh !important;">
  <a data-offcanvas-trigger="off-canvas"></a>
  <!--<button (click)="TestUrl()">test</button>-->
  <router-outlet></router-outlet>
</div>
