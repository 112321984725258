import { Injectable, EventEmitter } from "@angular/core";
import { MensajeNavBar } from "./nav-bar-mensajes.object";

import { Observable, Subject } from 'rxjs';
//import {  } from "rxjs";

@Injectable()
export class NavBarService {
  private Open: boolean;
  private ActivarMenu: boolean;
  public Src: string;

  private Mensajes: Array<MensajeNavBar>;
  private MensajeObservable: Subject<MensajeNavBar>;
  private MensajeObservableClear: Subject<boolean>;
  Deleted = new EventEmitter();

  constructor() {
    this.Mensajes = [];
    this.Open = false;
    this.ActivarMenu = false;
    this.MensajeObservable = new Subject();
    this.MensajeObservableClear = new Subject();
    this.SrcDefault();
  }
  OpenCloseNav = () => {
    if (!this.Open) {
      document.getElementById("mySidenav").style.width = "320px";
      document.getElementById("main").style.marginLeft = "320px";
      this.Open = true;
    } else {
      document.getElementById("mySidenav").style.width = "0";
      document.getElementById("main").style.marginLeft = "0";
      this.Open = false;
    }
  }

  SrcDefault(): void {
    this.Src = "alarm-006";//"icons/006-alarm.png";
  }

  SrcNewMessage(): void {
    this.Src = "alarm";//"icons/alarm.png";
  }

  AddMensajes(mensaje: MensajeNavBar) {
    //this.Mensajes.push(mensaje);
    this.MensajeObservable.next(mensaje);
  }

  ClearMensajes(): Observable<boolean> {
    return this.MensajeObservableClear;
  }

  Clear(): void {
    this.MensajeObservableClear.next(true);
  }

  GetMensaje(): Observable<MensajeNavBar> {
    return this.MensajeObservable;
  }

  GetMensajesArray(): Array<MensajeNavBar> {
    return this.Mensajes;
  }

  ActivarMenuNavBar() {
    setTimeout(() => {
      this.ActivarMenu = true;
    }, 3000);

  }

  DesactivarMenuNavBar() {
    this.ActivarMenu = false;
  }

  GetMensajesUsuario(mensajes: Array<MensajeNavBar>, key: string) {


    //let mensajes = this.GetMensajesLocalStorage(key).concat(arrayMensajes);
    if (mensajes.length > 0) {
      mensajes.forEach(f => {
        this.AddMensajes(f);
      })
    }
    localStorage.setItem(`mensajes.${key}`, JSON.stringify(mensajes));


  }

  GetDataMensajes(key: string) {
    let promise = new Promise((resolve, reject) => {
      let request = indexedDB.open('mensajes', 2);

      request.onupgradeneeded = function (e: any) {
        if (request.transaction.objectStoreNames.length == 0) {
          e.target.transaction.abort();
          resolve([]);
        }
        //dbExists = false;
      }

      request.onsuccess = (event: any) => {
        let db = request.result;

        db.onerror = (event: any) => {
          console.log(event);
        }
        let transaccion = null;

        try {
          transaccion = db.transaction('mensaje.' + key, 'readwrite');
        } catch (ex) {
          console.debug(ex);
        }

        if (transaccion != null) {
          let store = transaccion.objectStore('mensaje.' + key);

          let all = store.getAll();

          all.onsuccess = (data: any) => {
            let mensajes: Array<MensajeNavBar> = data.target.result;
            if (mensajes.length > 0) {
              store.clear();
              //indexedDB.open().transaction.objectStore().clear
            }
            resolve(mensajes)
          };
        } else { resolve([]); }
      }
    })

    return promise;
  }

  GetMensajesLocalStorage(key: string) {
    let mensajes = localStorage.getItem(`mensajes.${key}`)
    let mensajesArray = JSON.parse(mensajes);
    if (Array.isArray(mensajesArray)) {
      return mensajesArray;
    }
    return [];
  }

  OnDeleted(id: string) {
    this.Deleted.emit(id);
  }
}
