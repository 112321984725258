
import { Routes } from "@angular/router";
//import { MainSolicitudModifForecastComponent } from "../demand-supply/dp-solicitud-modif-forecast/main-solicitud-modif-forecast/main-solicitud-modif-forecast.component";

const routes: Routes = [
  {
    path: 'operaciones', children: [
      {
        path: 'solicitudforecast', loadChildren: () => import('./dp-solicitud-modif-forecast/dp-solicitud-modif-forecast.module')
          .then(t => t.DpSolicitudModifForecastModule)
      },
      {
        path: 'evaluacionforecast', loadChildren: () => import('./dp-evaluacion-modif-forecast/dp-evaluacion-modif-forecast.module')
          .then(t => t.DpEvaluacionModifForecastModule)
      },
      {
        path: 'planproducciondos', loadChildren: () => import('./sp-plan-produccion/sp-plan-produccion.module')
          .then(t => t.SpPlanProduccionModule)
      },
      {
        path: 'planproduccion', loadChildren: () => import('./sp-plan-maestro-prod/sp-plan-maestro-prod.module')
          .then(t => t.SpPlanMaestroProdModule)
      }
    ]    
  },
  {
    path: 'reportes', children: [
      {
        path: 'sop', loadChildren: () => import('./dp-reporte-sop/dp-reporte-sop.module')
          .then(t => t.DpReporteSopModule)
      },
      {
        path: 'errorpronostico', loadChildren: () => import('./dp-reporte-error-pronostico/dp-reporte-error-pronostico.module')
          .then(t => t.DpReporteErrorPronosticoModule)
      }//,
      //{
      //  path: 'alertas', loadChildren: () => import('./reporte-alertas/reporte-alertas.module')
      //    .then(t => t.ReporteAlertasModule)
      //}
    ]
  }
  //{
  //  path: "login/accessdenied", component: AccesoDenegadoComponent
  //} example
];


export function getRoutesDemandSupply() {
  return routes;
}
