import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vesta-acceso-denegado',
  templateUrl: './acceso-denegado.component.html',
  styleUrls: ['./acceso-denegado.component.css']
})

export class AccesoDenegadoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $("body").css("background-color", "#D4DFE6");
    $(".Container").css("margin-top", "10%");
  }

}
