import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccesoDenegadoComponent } from 'vesta';
import { VestaMenuComponent } from 'vesta-menu';
import { getRoutesSecuenciaLinea } from './secuencia-linea/secuencia-linea';
import { getRoutesDemandSupply } from './demand-supply/routes-demand-supply';

const routes: Routes = [
  {
    path: "", redirectTo: "menu", pathMatch: "full"
  },
  {
    path: "login/accessdenied", component: AccesoDenegadoComponent
  }
];

function getRoutes() {
  // Let's make this an array
  let routesSecuenciaLinea = getRoutesSecuenciaLinea();
  let routesDemand = getRoutesDemandSupply();
  let allRoutes = routes.concat(routesSecuenciaLinea).concat(routesDemand);
  return allRoutes;
};

@NgModule({
  imports: [RouterModule.forRoot(getRoutes())],
  exports: [RouterModule]
})
export class AppRoutingModule { }
