import { Component } from '@angular/core';
import { NavBarService } from 'nav-bar-mensajes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private navBarService: NavBarService) {}
  title = 'ClientApp';
  _navBarService = this.navBarService;


  Logout() {
    //this.webPush.Connection().Auth.signOut()
    //this.authService.signoutRedirect();
  }

  NavOpen(): void {
    this.navBarService.OpenCloseNav();
  }
}
