import { Injectable } from '@angular/core';

@Injectable()

export class Mapper {
  static Convert<T, TW>(inputObject: T, outputObject: TW): TW {
    var keysObject = Object.keys(inputObject);
    for (var propiedad of keysObject) {
        outputObject[propiedad] = inputObject[propiedad];
    }
    return outputObject;
  }

  static ConvertListObject<T, TW>(inputObjectList: T[], outputObject: TW): TW[] {
      var list: TW[] = [];
      //var documento: TW;
      inputObjectList.Enumerable().forEach(f => {
          var a = (Object as IObjectextended).create(outputObject);
          var objList = this.Convert(f, a);
          list.push(objList);
      });
      return list;
  }

  static ConvertListObject2<T, TW>(inputObjectList: T[], outputObject: any): TW[] {
      var list: TW[] = [];
      //var documento: TW;
      inputObjectList.Enumerable().forEach(f => {
          var a = new outputObject();
          var objList = this.Convert(f, a);
          list.push(objList);
      });
      return list;
  }
}

export interface IObjectextended extends Object {
  create<T>(o: T);
}
