import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "filterBy",
    pure: false
})
export class FilterByPipe implements PipeTransform {
    public transform(value: any, key: string, term: string): boolean {
        return value.filter((item) => {
            if (item.hasOwnProperty(key)) {
                if (term) {
                    let regExp: RegExp = new RegExp("\\b" + term, "gi");
                    return regExp.test(item[key]);
                } else {
                    return true;
                }
            } else {
                return false;
            }
        });
    }
}