import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { NavbarMensajesComponent } from "./nav-bar-mensajes.component";
import { NavBarService } from "./nav-bar-mensajes.service";
import { FilterByPipe } from "./filter.pipe";
//import { VestaAuthenticationUserModule } from 'vesta-authentication';

@NgModule({
  imports: [CommonModule, FormsModule],
    declarations: [NavbarMensajesComponent,FilterByPipe],
    exports: [NavbarMensajesComponent, FilterByPipe],
    providers: [NavBarService]
})

export class NavBarMensajesModule {}
