import { Routes } from "@angular/router";

 const routes: Routes = [
   {
     path: 'operaciones', children: [
       {
         path: 'distribucion', loadChildren: () => import('./distribucion/distribucion.module')
           .then(t => t.DistribucionModule)
       },
       {
         path: 'secuenciamiento', loadChildren: () => import('./secuenciamiento-linea/secuenciamiento-linea.module')
           .then(t => t.SecuenciamientoLineaModule)
       },
       {
         path: 'distribucioncliente', loadChildren: () => import('./distribucion-cliente/distribucion.module')
           .then(t => t.DistribucionModule)
       },
     ]
   }
 ];

export function getRoutesSecuenciaLinea() {
  return routes;
}


